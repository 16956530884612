import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import moment from 'moment'

import { StyledEditIcon, StyledLockIcon, StyledDeleteIcon } from 'shared/components/TableIcons'
import {
   StyledTable,
   StyledTableCell,
   StyledContentTableCell,
   StyledCollapseTableCell,
   StyledTableRow,
} from 'shared/components/StyledTableComponents'
import { UserRoles } from 'shared/consts'

import { ModalInfoType } from '../../types'

interface Props {
   isAdmin: boolean
   data: UsersType
   salesRepOn: boolean
   loading: boolean
   handleRefetch: () => void
   setModalInfo: React.Dispatch<React.SetStateAction<ModalInfoType | undefined>>
   handleSalesRepChange: (remove: boolean, userUuid: string) => void
   isLoading: boolean
}

export default function MobileTable({
   isAdmin,
   data,
   loading,
   salesRepOn,
   handleRefetch,
   setModalInfo,
   handleSalesRepChange,
   isLoading,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'USERS_AND_SUBSCRIPTIONS'])
   const [collapse, setCollapse] = useState<string[]>([])

   function handleCollapseProduct(uuid: string) {
      if (collapse.includes(uuid)) {
         setCollapse(collapse.filter((item) => item !== uuid))
      } else {
         const tempCollapseProducts = [...collapse]
         tempCollapseProducts.push(uuid)
         setCollapse([...tempCollapseProducts])
      }
   }

   // TODO: Remove after backend fix
   function returnFormattedDate(date: string) {
      if (date) {
         const [day, month, year, hours, minutes] = date.match(/\d+/g) as string[]
         const newDate = new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes))
         newDate.setHours(newDate.getHours() + 1)

         return moment(newDate).format('DD-MM-YYYY, HH:mm')
      }
      return '-'
   }

   return (
      <StyledTable>
         <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
            <TableRow>
               <StyledTableCell>{t('TRANSLATION:formFields.name')} </StyledTableCell>
               <StyledTableCell>{t('TRANSLATION:formFields.surname')}</StyledTableCell>
               <StyledTableCell>{t('TRANSLATION:formFields.email')}</StyledTableCell>
            </TableRow>
         </TableHead>
         <TableBody>
            {data.map((item) => (
               <React.Fragment key={item.uuid}>
                  <StyledTableRow onClick={() => handleCollapseProduct(item.uuid)}>
                     <StyledTableCell>{item.name}</StyledTableCell>
                     <StyledTableCell>{item.surname}</StyledTableCell>
                     <StyledTableCell>{item.email}</StyledTableCell>
                  </StyledTableRow>
                  <TableRow sx={{ '>td': { backgroundColor: 'white' } }}>
                     <TableCell style={{ padding: '0' }} colSpan={6}>
                        <Collapse in={collapse.includes(item.uuid)} timeout="auto">
                           <Table>
                              <TableRow>
                                 <StyledCollapseTableCell>
                                    {t('TRANSLATION:formFields.phoneNumber')}
                                 </StyledCollapseTableCell>
                                 <TableCell />
                                 <StyledCollapseTableCell>{item.phoneNumber}</StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell>
                                    {t('TRANSLATION:formFields.lastLoginDate')}
                                 </StyledCollapseTableCell>
                                 <TableCell />
                                 <StyledCollapseTableCell>
                                    {returnFormattedDate(item.lastLogin)}
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell>{t('TRANSLATION:formFields.search')}</StyledCollapseTableCell>
                                 <TableCell />
                                 <StyledCollapseTableCell>{item.searchCount}</StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell>
                                    {t('TRANSLATION:formFields.ordersValue')}
                                 </StyledCollapseTableCell>
                                 <TableCell />
                                 <StyledCollapseTableCell>{item.formattedOrdersValue}</StyledCollapseTableCell>
                              </TableRow>
                              {isAdmin && (
                                 <TableRow>
                                    <StyledCollapseTableCell>
                                       {t('USERS_AND_SUBSCRIPTIONS:salesRep')}
                                    </StyledCollapseTableCell>

                                    <StyledCollapseTableCell />
                                    <StyledContentTableCell>
                                       <Tooltip
                                          title={t('USERS_AND_SUBSCRIPTIONS:onlyInPro') || ''}
                                          disableHoverListener={salesRepOn}
                                       >
                                          <div>
                                             <Checkbox
                                                checked={item.roles.includes(UserRoles.SALES_REP)}
                                                disabled={isLoading || loading || !salesRepOn}
                                                onClick={() => {
                                                   handleSalesRepChange(
                                                      item.roles.includes(UserRoles.SALES_REP),
                                                      item.uuid
                                                   )
                                                   handleRefetch()
                                                }}
                                             />
                                          </div>
                                       </Tooltip>
                                    </StyledContentTableCell>
                                 </TableRow>
                              )}
                              {isAdmin && (
                                 <TableRow>
                                    <StyledContentTableCell colSpan={3}>
                                       <>
                                          <IconButton
                                             onClick={() =>
                                                setModalInfo({
                                                   userInfo: {
                                                      ...item,
                                                   },
                                                   modalType: 'EDIT',
                                                })
                                             }
                                          >
                                             <StyledEditIcon />
                                          </IconButton>
                                          <IconButton
                                             onClick={() =>
                                                setModalInfo({
                                                   userInfo: {
                                                      ...item,
                                                   },
                                                   modalType: 'CHANGEPASSWORD',
                                                })
                                             }
                                          >
                                             <StyledLockIcon />
                                          </IconButton>
                                          <IconButton
                                             onClick={() =>
                                                setModalInfo({
                                                   userInfo: {
                                                      ...item,
                                                   },
                                                   modalType: 'DELETE',
                                                })
                                             }
                                          >
                                             <StyledDeleteIcon />
                                          </IconButton>
                                       </>
                                    </StyledContentTableCell>
                                    <TableCell />
                                 </TableRow>
                              )}
                           </Table>
                        </Collapse>
                     </TableCell>
                  </TableRow>
               </React.Fragment>
            ))}
         </TableBody>
      </StyledTable>
   )
}
