import ContainedButton from 'shared/components/Buttons/ContainedButton'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { t } from 'i18next'

import {
   StyledContentTableCell,
   StyledTableCell,
   StyledTable,
   StyledTableHead,
} from 'shared/components/StyledTableComponents'
import { StyledDeleteIcon, StyledEditIcon } from 'shared/components/TableIcons'
import useAdditionalServicesQuery from 'shared/store/useAddtionalServices'
import { LocationType } from 'shared/store/useLocations/types'

import { ModalInfoType } from '../../types'
import Modal from '../../Modal'

interface Props {
   activeLocation: LocationType
}
export default function ServicesList({ activeLocation }: Props) {
   const { data, isFetching } = useAdditionalServicesQuery(activeLocation.locationSettings.uuid)
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()

   function renderContent() {
      if (isFetching) {
         return (
            <Box sx={{ textAlign: 'center' }}>
               <CircularProgress color="primary" />
            </Box>
         )
      }
      if (data && data.additionalServices.length === 0) {
         return (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
               <Typography variant="h5" color="primary">
                  {t('SERVICES:noServices')}
               </Typography>
               <ContainedButton
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={() =>
                     setModalInfo({ locationUuid: activeLocation.locationSettings.uuid, modalType: 'CREATE' })
                  }
               >
                  {t('SERVICES:addServiceButtonLabel')}
               </ContainedButton>
            </Box>
         )
      }
      if (data) {
         return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
               <StyledTable>
                  <StyledTableHead>
                     <TableRow>
                        <StyledTableCell>{t('TRANSLATION:formFields.serviceName')} </StyledTableCell>
                        <StyledTableCell>{t('TRANSLATION:formFields.defaultNetPrice')}</StyledTableCell>
                        <StyledTableCell>VAT</StyledTableCell>
                        <StyledTableCell>{t('TRANSLATION:formFields.defaultGrossPrice')}</StyledTableCell>
                        <StyledTableCell>{t('TRANSLATION:edit')}</StyledTableCell>
                        <StyledTableCell>{t('TRANSLATION:delete')}</StyledTableCell>
                     </TableRow>
                  </StyledTableHead>
                  <TableBody>
                     {data.additionalServices.map((item) => (
                        <TableRow key={item.uuid}>
                           <StyledContentTableCell>{item.name}</StyledContentTableCell>
                           <StyledContentTableCell>{item.formattedDefaultNetPrice}</StyledContentTableCell>
                           <StyledContentTableCell>{item.taxRate}</StyledContentTableCell>
                           <StyledContentTableCell>{item.formattedDefaultGrossPrice}</StyledContentTableCell>
                           <StyledContentTableCell>
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({
                                       locationUuid: activeLocation.locationSettings.uuid,
                                       modalType: 'EDIT',
                                       serviceUuid: item.uuid,
                                       serviceInfo: {
                                          ...item,
                                       },
                                    })
                                 }
                              >
                                 <StyledEditIcon />
                              </IconButton>
                           </StyledContentTableCell>
                           <StyledContentTableCell>
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({
                                       locationUuid: activeLocation.locationSettings.uuid,
                                       serviceUuid: item.uuid,
                                       serviceName: item.name,
                                       modalType: 'DELETE',
                                    })
                                 }
                              >
                                 <StyledDeleteIcon />
                              </IconButton>
                           </StyledContentTableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </StyledTable>
               <ContainedButton
                  onClick={() =>
                     setModalInfo({ locationUuid: activeLocation.locationSettings.uuid, modalType: 'CREATE' })
                  }
                  variant="contained"
                  sx={{ mt: 1 }}
               >
                  {t('SERVICES:addServiceButtonLabel')}
               </ContainedButton>
            </Box>
         )
      }
      return null
   }

   return (
      <Grid item xs={12} md={10} sx={{ px: 3, py: 1 }}>
         {renderContent()}
         {modalInfo && <Modal modalInfo={modalInfo} handleClose={() => setModalInfo(undefined)} />}
      </Grid>
   )
}
