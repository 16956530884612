import IconButton from '@mui/material/IconButton'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import moment from 'moment'
import React from 'react'

import { StyledTable, StyledTableCell, StyledContentTableCell } from 'shared/components/StyledTableComponents'
import { StyledEditIcon, StyledLockIcon, StyledDeleteIcon } from 'shared/components/TableIcons'
import { UserRoles } from 'shared/consts'

import { ModalInfoType } from '../../types'

interface Props {
   isAdmin: boolean
   data: UsersType
   salesRepOn: boolean
   loading: boolean
   handleRefetch: () => void
   setModalInfo: React.Dispatch<React.SetStateAction<ModalInfoType | undefined>>
   handleSalesRepChange: (remove: boolean, userUuid: string) => void
   isLoading: boolean
}

export default function DesktopTable({
   isAdmin,
   data,
   loading,
   salesRepOn,
   handleRefetch,
   setModalInfo,
   handleSalesRepChange,
   isLoading,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'USERS_AND_SUBSCRIPTIONS'])

   // TODO: Remove after backend fix
   function returnFormattedDate(date: string) {
      if (date) {
         const [day, month, year, hours, minutes] = date.match(/\d+/g) as string[]
         const newDate = new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes))
         newDate.setHours(newDate.getHours() + 1)

         return moment(newDate).format('DD-MM-YYYY, HH:mm')
      }
      return '-'
   }

   return (
      <StyledTable>
         <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
            <TableRow>
               <StyledTableCell>{t('TRANSLATION:formFields.name')} </StyledTableCell>
               <StyledTableCell>{t('TRANSLATION:formFields.surname')}</StyledTableCell>
               <StyledTableCell>{t('TRANSLATION:formFields.email')}</StyledTableCell>
               <StyledTableCell>{t('TRANSLATION:formFields.phoneNumber')}</StyledTableCell>
               <StyledTableCell>{t('TRANSLATION:formFields.lastLoginDate')}</StyledTableCell>
               <StyledTableCell>{t('TRANSLATION:formFields.search')}</StyledTableCell>
               <StyledTableCell>{t('TRANSLATION:formFields.ordersValue')}</StyledTableCell>
               <StyledTableCell />
               {isAdmin && <StyledTableCell>{t('USERS_AND_SUBSCRIPTIONS:salesRep')}</StyledTableCell>}
            </TableRow>
         </TableHead>
         <TableBody>
            {data.map((item) => (
               <TableRow sx={{ '>td': { backgroundColor: 'white' } }} key={item.uuid}>
                  <StyledContentTableCell>{item.name}</StyledContentTableCell>
                  <StyledContentTableCell>{item.surname}</StyledContentTableCell>
                  <StyledContentTableCell>{item.email}</StyledContentTableCell>
                  <StyledContentTableCell>{item.phoneNumber}</StyledContentTableCell>
                  <StyledContentTableCell>{returnFormattedDate(item.lastLogin)}</StyledContentTableCell>
                  <StyledContentTableCell>{item.searchCount}</StyledContentTableCell>
                  <StyledContentTableCell>{item.formattedOrdersValue}</StyledContentTableCell>
                  {!isAdmin ? (
                     <StyledContentTableCell />
                  ) : (
                     <StyledContentTableCell>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: { lg: 'row', xs: 'column' },
                           }}
                        >
                           <IconButton
                              onClick={() =>
                                 setModalInfo({
                                    userInfo: {
                                       ...item,
                                    },
                                    modalType: 'EDIT',
                                 })
                              }
                           >
                              <StyledEditIcon />
                           </IconButton>
                           <IconButton
                              disabled={!isAdmin}
                              onClick={() =>
                                 setModalInfo({
                                    userInfo: {
                                       ...item,
                                    },
                                    modalType: 'CHANGEPASSWORD',
                                 })
                              }
                           >
                              <StyledLockIcon />
                           </IconButton>
                           {isAdmin && !item.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) && (
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({
                                       userInfo: {
                                          ...item,
                                       },
                                       modalType: 'DELETE',
                                    })
                                 }
                              >
                                 <StyledDeleteIcon />
                              </IconButton>
                           )}
                        </Box>
                     </StyledContentTableCell>
                  )}
                  {isAdmin && (
                     <StyledContentTableCell>
                        <Tooltip title={t('USERS_AND_SUBSCRIPTIONS:onlyInPro') || ''} disableHoverListener={salesRepOn}>
                           <div>
                              <Checkbox
                                 checked={item.roles.includes(UserRoles.SALES_REP)}
                                 disabled={isLoading || loading || !salesRepOn}
                                 onClick={() => {
                                    handleSalesRepChange(item.roles.includes(UserRoles.SALES_REP), item.uuid)
                                    handleRefetch()
                                 }}
                              />
                           </div>
                        </Tooltip>
                     </StyledContentTableCell>
                  )}
               </TableRow>
            ))}
         </TableBody>
      </StyledTable>
   )
}
